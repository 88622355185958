<template v-if="formActive">

    <FormItem id="name" :label="`Name`" v-model="calcunit.name" :required="true" />
    <FormItem id="code" :label="`Code`" v-model="calcunit.code" :required="true" sizeItem="2" />
                        
</template>

<script>
    export default {
        name: 'CalculationunitForm',
        props: ['itemData'],
        data() {
            return {
                calcunit: {},
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.calcunit = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.calcunit;
            },
            setData(data) {
                this.calcunit = data
            },
            reset() {
                this.calcunit = {
                    'name': null,
                    'code': null
                }
                this.loading = false
                this.invalid = {}
            }
        },
        mounted() {
            this.reset()

        }
    }
</script>