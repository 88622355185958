<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="['id', 'name', 'code']"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="code" :header="$t('overview.code')" style="width: 40%"></Column>
        </template>

        <template #form>
            <CalculationunitForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import CalculationunitForm from '@/components/calculationunit/CalculationunitForm.vue';
import calculationunitService from '@/services/CalculationunitService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        CalculationunitForm
    },
    data() {
        return {
            item: {},
            itemService: calculationunitService,
            itemFormRef: null
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>